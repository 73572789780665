.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: 'Gorditas';
  src: url('../src/Gorditas-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.App-header {
  background-color: #282c34;
  background-size: cover;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}
.App-header2 {
  font-family: 'Gorditas';
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: calc(36px + 2vmin);
  color: white;
  text-shadow: #8c5024 3px 0px 0px, #8c5024 2.83487px 0.981584px 0px, #8c5024 2.35766px 1.85511px 0px, #8c5024 1.62091px 2.52441px 0px, #8c5024 0.705713px 2.91581px 0px, #8c5024 -0.287171px 2.98622px 0px, #8c5024 -1.24844px 2.72789px 0px, #8c5024 -2.07227px 2.16926px 0px, #8c5024 -2.66798px 1.37182px 0px, #8c5024 -2.96998px 0.42336px 0px, #8c5024 -2.94502px -0.571704px 0px, #8c5024 -2.59586px -1.50383px 0px, #8c5024 -1.96093px -2.27041px 0px, #8c5024 -1.11013px -2.78704px 0px, #8c5024 -0.137119px -2.99686px 0px, #8c5024 0.850987px -2.87677px 0px, #8c5024 1.74541px -2.43999px 0px, #8c5024 2.44769px -1.73459px 0px, #8c5024 2.88051px -0.838247px 0px;
}
.nftTitle{
  font-size: calc(60px + 2vmin);
}
.backgroundImage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/public/background.png");
  background-color: black;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
